/* Container */
.container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    position: relative;
  }
  
  /* Paper (Card) */
  .paper {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Content inside the card */
  .content h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Date Picker Container */
  .date-picker-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  
  /* Date Picker Input */
  .date-picker {
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    color: black;
  }
  
  /* Date Picker Input on Focus */
  .date-picker:focus {
    border-color: #333;
    outline: none;
  }
  
  /* Fetch Button */
  .fetch-button {
    padding: 10px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* Fetch Button on Hover */
  .fetch-button:hover {
    background-color: #155a9c;
  }
  
  /* Loader */
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 100px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Winner Container */
 /* Countdown overlay styles */
.countdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .countdown-number {
    font-size: 80px;
    color: white;
    text-align: center;
    font-weight: bold;
    animation: pulse 1s infinite;
  }
  
  /* Pulse animation for countdown */
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Fireworks and winner container styles */
  .winner-container {
    position: relative;
    z-index: 1000;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 20px;
    color: black;
  }
  
  
  .winner-text {
    font-size: 2.5rem; /* Adjust font size for better visibility */
    color: #000; /* White color for text */
    margin: 0;
  }
  
  .winner-name {
    font-size: 3rem; /* Larger font size for the winner's name */
    color: #ffd700; /* Golden color for the winner's name */
    margin: 10px 0 20px 0;
  }
  
  .fireworks-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allow fireworks to wrap if space is limited */
  }
  
    
  /* Sparks Background */

  @keyframes firework {
    0% { 
      transform: translate(-50%, 60vh);
      width: 0.5vmin;
      opacity: 1;
    }
    50% { 
      width: 0.5vmin;
      opacity: 1;
    }
    100% { 
      width: 45vmin; 
      opacity: 0; 
    }
  }
  
  .firework,
  .firework::before,
  .firework::after {
    --top: 60vh;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5vmin;
    aspect-ratio: 1;
    background:
      /* random backgrounds */
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 50% 00%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 00% 50%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 50% 99%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 99% 50%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 95% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 10% 60%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 31% 80%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 10%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 90% 23%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 45% 20%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 53% 99%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 99% 50%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 1% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 20% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 10% 60%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 31% 40%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 10%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 90% 23%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 45% 20%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 13% 24%
      ;
    background-size: 0.5vmin 0.5vmin;
    background-repeat: no-repeat;
    animation: firework 1s infinite;
    animation-delay: var(--delay, 0s); /* Use custom property for delay */

  }
  
  .firework::before {
    transform: translate(-50%, -50%) rotate(25deg) !important; 
  }
  
  .firework::after {
    transform: translate(-50%, -50%) rotate(-37deg) !important;
  }
  



   
  .firework2,
  .firework2::before,
  .firework2::after {
    --top: 60vh;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5vmin;
    aspect-ratio: 1;
    background:
      /* random backgrounds */
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 50% 00%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 00% 50%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 50% 99%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 99% 50%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 95% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 10% 60%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 31% 80%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 10%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 90% 23%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 45% 20%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 53% 99%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 99% 50%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 1% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 20% 90%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 10% 60%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 31% 40%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 80% 10%,
      radial-gradient(circle, #0000FF 0.2vmin, #0000 0) 90% 23%,
      radial-gradient(circle, #0000FF 0.3vmin, #0000 0) 45% 20%,
      radial-gradient(circle, #0000FF 0.5vmin, #0000 0) 13% 24%
      ;
    background-size: 0.5vmin 0.5vmin;
    background-repeat: no-repeat;
    animation: firework 1s infinite;
    animation-delay: var(--delay, 0s); /* Use custom property for delay */

  }
  
  .firework2::before {
    transform: translate(-50%, -50%) rotate(25deg) !important; 
  }
  
  .firework2::after {
    transform: translate(-50%, -50%) rotate(-37deg) !important;
  }
  


.button-91 {
    color: #fff;
    padding: 15px 25px;
    background-color: #38D2D2;
    background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    font-weight: bold;
    font-size: 16px;
  
    border: 0;
  
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
    cursor: pointer;
  }

  .button-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .button-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 500px; /* Adjust based on your layout needs */
  }
  
  .button-row.centered {
    justify-content: center;
  }
  
  .show-winner-button {
    width: 48%; /* Adjust the width to fit two buttons in a row */
  }
  
  .button-row.centered .show-winner-button {
    width: 100%; /* The centered button takes full width */
  }
  


  /* CSS for logo and main heading */
.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 150px;
    animation: float 3s infinite ease-in-out;
  }
  
  .main-heading {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }
  
  /* Keyframes for floating animation */
  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  